import React from "react";
import { Container } from "react-bootstrap";

const TestingSpeed = () => {
  return (
    <div>
      <Container>
        <h1>testing speed</h1>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book
        </p>
        <h2>dfsfdsf</h2>
      </Container>
    </div>
  );
};

export default TestingSpeed;
